/* eslint func-names: ["off"] */
/* eslint prefer-arrow-callback: ["off"] */

import Vue from 'vue';
import VueForm from 'vue-form';
import VCalendar from 'v-calendar';
import 'jquery-address';
import 'owl.carousel/dist/owl.carousel';
import '@situation/setdesign.common';
import '@situation/setdesign.directives.owl-a11y';
import '@situation/setdesign.directives.sticky-header';
import '@situation/setdesign.directives.toggle';
import '@situation/setdesign.nav-menu';
import '@situation/setdesign.modal';
import '@situation/setdesign.video';
import '@situation/setdesign.tag-manager';
import '@situation/setdesign.read-more';
import '@situation/setdesign.accordion';
import '@situation/setdesign.sub-nav';
import '@situation/setdesign.filters';
import '@situation/setdesign.media-carousel';
import '@situation/setdesign.responsive-picture';
import '@situation/setdesign.lazy-image';
import '@situation/setdesign.message';
import '@situation/vc-library';
import {
    $, addAction, INIT, importAll, registerVueComponents,
} from '@situation/setdesign.util';

importAll(require.context('../images', true));
importAll(require.context('../fonts', true));
importAll(require.context('./components', false));
importAll(require.context('./components/show-filters', false));
addAction(INIT, () => {
    const VUE_APP_ID = '#app';
    if (!$(VUE_APP_ID).length) {
        return;
    }

    Vue.use(VueForm);
    Vue.use(VCalendar);
    registerVueComponents(require.context('./components/inquiry-form', false), Vue);
    registerVueComponents(require.context('./components/calendar', false), Vue);
    Vue.filter('monthToString', (value) => {
        const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        return monthNames[value - 1];
    });

    new Vue({}).$mount(VUE_APP_ID);
});

$(function () {
    $('.search-toggle').click(function () {
        const $this = $(this);

        $('#search-overlay').toggle();
        $this.find('.search-icon').toggle();
        $this.find('.close-icon').toggle();
    });

    $('.mobile-menu-toggle').click(function () {
        $('#mobile-nav').toggle();
        // Toggle the search toggle button and the menu button and show the 'X'
        $('#header-primary-menu').toggle();
        $('#mobile-menu-close-button').toggle();
        // Stop the body of the page from scrolling
        $('body').toggleClass('overflow-hidden');
    });

    $('#more-toggle').click(function () {
        $('#more-submenu').toggle();
        $(this).find('.fa-angle-down').toggleClass('fa-rotate-180');
    });

    $('#mobile-more-toggle').click(function () {
        $('#mobile-more-menu').toggle();
        $(this).find('.fa-angle-down').toggleClass('fa-rotate-180');
    });

    $('.carousel-item', '.show-neighbors').each(function () {
        const $this = $(this);
        let $next = $this.next();

        if (!$next.length) {
            $next = $this.siblings(':first');
        }

        $next.children(':first-child')
            .clone()
            .appendTo($this)
            .find('.container')
            .addClass('no-margin-left');
    }).each(function () {
        const $this = $(this);
        let $prev = $this.prev();

        if (!$prev.length) {
            $prev = $this.siblings(':last');
        }

        $prev.children(':nth-last-child(2)')
            .clone()
            .prependTo($this)
            .find('.container')
            .addClass('no-margin-right');
    });
});
